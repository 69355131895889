.MaterialButton {
  position: relative;
  overflow: hidden;
  transition: background-color .3s;
  cursor: pointer;
}

.MaterialButton-styled {
  background-color: #2196F3;
  color: white;
  text-align: center;
}

.MaterialButton-styled:hover {
  background-color: #03A9F4;
}

.MaterialButton-effect {
  pointer-events: none;
  position: absolute;
  display: block;
  width: 0;
  padding-top: 0;
  border-radius: 100%;
  /*background-color: rgba(236, 240, 241, .5);*/
  background-color: rgba(100, 100, 100, .3);
  transform: translate(-50%, -50%);
}

.MaterialButton-effectActive {
  cursor: pointer;
  padding: 150px;
  background-color: transparent;
  transition: padding .3s ease-out, background-color .7s ease-out;
}
