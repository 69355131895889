.PostDetail-wrapper,
.PostDetail-errorWrapper {
  margin: 15px 6px;
  background: white;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12);
}

.PostDetail-errorWrapper {
  background-color: red;
}

.PostDetail-header {
  padding: 20px;
  background-color: rgb(232, 232, 232);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PostDetail-title {
  background: none;
  border: none;
  padding: 8px;
  font-size: 1.2em;
  border-bottom: 1px solid transparent;
  flex: 3;
}

.PostDetail-title:hover,
.PostDetail-title:focus {
  border-bottom: 1px solid #03A9F4;
}

.PostDetail-id {
  flex: 2;
  font-size: 13px;
  text-align: center;
  color: gray;
}

.PostDetail-dates {
  flex: 2;
  margin: 10px 0;
  color: #2c3e50;
  text-align: right;
}

.PostDetail-editItems {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0;
}

.PostDetail-editImage {
  margin: 0 20px;
}

.PostDetail-editDescription {
  margin: 20px;
}

.PostDetail-editUrl {
  flex-basis: 300px;
}

.PostDetail-editUrlNotUnique {
  color: red;
  font-weight: bold;
}

.PostDetail-editState {
  flex-basis: 150px;
  margin: 0 20px;
}

.PostDetail-input {
  padding: 10px;
  width: 100%;
  border: 1px solid rgb(232, 232, 232);
}

.PostDetail-dropdown {
  padding: 10px;
  width: 100%;
  border: 1px solid rgb(232, 232, 232);
}

.PostDetail-button {
  padding: 10px;
  margin: 20px 0;
}

.PostDetail-headerButton {
  padding: 10px;
  margin-left: 20px;
}

.PostDetail-text {
  min-height: 300px;
  padding: 10px;
  margin-right: 30px;
  flex: 1.2;
  border: 1px solid rgb(232, 232, 232);
  overflow-x: scroll;
  margin: 10px 20px;
}

.PostDetail-mdOutput {
  flex: 1;
  overflow-x: scroll;
  margin: 10px 20px 10px 0;
}

.PostDetail-multiedit {
  display: flex;
  justify-content: space-between;
}
