.PostListing-wrap {
  display: flex;
  justify-content: center;
}

.PostListing-item {
  flex: 1;
  margin: 5px;
  background-color: #ecf0f1;
  color: #2c3e50;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border: 1px solid rgb(224, 224, 224);
}

.PostListing-item:hover {
  background-color: white;
}

.PostListing-itemTitle {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(232, 232, 232);
  padding: 10px;
}

.PostListing-move {
  display: flex;
  align-items: center;
  padding: 20px;
  margin: 5px;
  font-weight: bold;
}

.PostListing-new {
  display: flex;
  align-items: center;
  padding: 20px;
  margin: 5px;
}

.PostListing-stat {
  margin: 3px 0;
  padding: 5px 10px;
}

.PostListing-statLabel {
  font-size: 11px;
  font-weight: bold;
}

.PostListing-statValue {
  font-size: 15px;
}

.PostListing-statValueEllipsis,
.PostListing-itemTitle {
  font-size: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
