* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #95a5a6;
  padding: 15px;
}
